<template>
  <base-section id="theme-features">
    <base-section-heading title="Our Vision">
      The vision at Breezy Software is to create and provide software with a primary goal to bring ease into our users lives. When we think Breezy, we think software made easy.
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ feature['text'] }}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          icon: 'mdi-puzzle-outline',
          title: 'Application Integration',
          text: 'Custom integrations for seamless data transfer between your systems.',
        },
        {
          icon: 'mdi-code-braces',
          title: 'Software Development',
          text: 'Software to solve any customer needs and difficulties.',
        },
        {
          icon: 'mdi-keyboard-outline',
          title: 'Web Design',
          text: 'Get your site built and customised to reflect your business.',
        },
        {
          icon: 'mdi-human-greeting',
          title: 'Training',
          text: 'Learn best practises and soft skills to use software to maximise your experience.',
        },
      ],
    }),
  }
</script>
